.mapboxgl-ctrl-geocoder {
  min-width: 180px !important;
  width: 305px !important;
  height: 36px !important;
}

@media screen and (max-width: 400px) {
  .mapboxgl-ctrl-geocoder {
    min-width: 150px !important;
    width: 150px !important;
  }
}

.mapboxgl-ctrl-geocoder input[type="text"] {
  height: 36px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 8px !important;
}

.mapboxgl-ctrl-top-right {
  z-index: 4 !important;
}
